// Usage: GetParamFromStringURL("http://localhost:3000/?page=1", "page") => 1
export default function GetParamFromStringURL(
  url?: string | undefined,
  param?: string
) {
  if (!url || !param) return "";

  let regex = new RegExp(`${param}=(\\d+)`);
  const match = url?.match(regex);
  return match ? match[1] : "";
}
