import { DropdownObject, SelectObjects } from "@/utils/types/common";
import CallAPI from "./CallAPI";
import GetParamFromStringURL from "./GetParamFromStringURL";
import { Dispatch, SetStateAction } from "react";

// Sentry
import * as Sentry from "@sentry/nextjs";

// Function used to configure the pagination of the dropdown
export const getPaginateDropdown = async (
  url: string,
  dropdownObject: DropdownObject,
  setDropdownObject: Dispatch<SetStateAction<DropdownObject>>,
  isRefetching?: boolean,
  setIsLoading?: Dispatch<SetStateAction<boolean>>,
  params?: Object
): Promise<SelectObjects[]> => {
  try {
    let paginatedDropdown = [] as SelectObjects[];
    await CallAPI(
      "GET",
      url,
      JSON.stringify({
        enabled: true,
        page: isRefetching ? dropdownObject.next : dropdownObject.first || "1",
        ...params,
      })
    ).then((res) => {
      if (setIsLoading) setIsLoading(true);

      if (
        !res.data ||
        !res.data.response["hydra:member"] ||
        !res.data.response["hydra:view"]
      )
        return;

      const dropdown: SelectObjects[] = res.data.response["hydra:member"].map(
        (obj: any) => {
          return {
            id: obj.id,
            toString: obj.toString,
          };
        }
      );

      const nextPage = !!res.data.response["hydra:view"]["hydra:next"]
        ? GetParamFromStringURL(
            res.data.response["hydra:view"]["hydra:next"],
            "page"
          )
        : "";

      setDropdownObject({
        dropdown: isRefetching
          ? [...dropdownObject.dropdown, ...dropdown]
          : dropdown,
        first: GetParamFromStringURL(
          res.data.response["hydra:view"]["hydra:first"],
          "page"
        ),
        last: !!res.data.response["hydra:view"]["hydra:next"]
          ? GetParamFromStringURL(
              res.data.response["hydra:view"]["hydra:next"],
              "page"
            )
          : "",
        next: nextPage,
        hasMore: !!res.data.response["hydra:view"]["hydra:next"],
      });

      if (setIsLoading) setIsLoading(false);

      paginatedDropdown = isRefetching
        ? [...dropdownObject.dropdown, ...dropdown]
        : dropdown;
    });

    return Promise.resolve(paginatedDropdown);
  } catch (err) {
    console.error(err);
    Sentry.captureException(err);
    return Promise.reject(err);
  }
};
