export async function getDefaultIds(listDefaults: string[]) {
  try {
    // Call the API with all the needed "toFind" values // Add more as needed
    const queryParams = listDefaults
      .map((item) => `defaultIds=${item}`)
      .join("&");

    const response = await fetch(`/api/getDefaultValue?${queryParams}`);
    const data = await response.json();
    // `data` contains all the default values in one object
    return data;
  } catch (error) {
    console.error("Error fetching default values:", error);
  }
}
