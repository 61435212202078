export const CacheKeys = {
  getEmp: "getEmp",
  getProject: "getProject",
  getStatus: "getStatus",
  Dropdown: {
    Employee: "getDropdownEmp",
    Project: "getDropdownProject",
    Status: "getDropdownStatus",
    Tasks: "getDropdownTasks",
  },
};
